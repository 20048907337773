function Banner() {
  return (
    <div id="bannerText">
      <p>
        We won Product #3 of the Day on&nbsp;
        <a href="https://www.producthunt.com/posts/giftpicker-by-presently">ProductHunt!</a> 🏆
      </p>
    </div>
  );
}

export default Banner;
